<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<!-- KeywordList -->
		<KeywordsList></KeywordsList>

		<!-- Source List -->
		<SourceList></SourceList>

		<!-- Show finish source evaluation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.source_evaluation.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import SourceList from "@/components/research/modules/gatherSources/source/List.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import KeywordsList from "@/components/research/modules/gatherSources/source/KeywordsList.vue";
import {
	validateCreateSource,
	validateEvaluateSource,
} from "@/utils/helpers/source";

export default {
	name: "SourceDocumentationPage",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		StageIntroDialog,
		SourceList,
		KeywordsList,
		StageFinishDialog,
	},

	created() {
		this.$eventBus.$on("finish-source-evaluation", (payload) => {
			this.validateAndProceed();
		});
	},

	methods: {
		validateAndProceed() {
			try {
				// First, validate how many sources are actually created
				validateCreateSource();

				// Second, validate how many sources are actually documented
				validateEvaluateSource();

				// Then, open the finish dialog
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-source-evaluation");
	},
};
</script>
